import React from "react";
import { Link } from "react-router-dom";
import principal from "../../assets/cat-4.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import principalImg from "../../assets/mittapalliPrince.jpg.jpg"
import "../CheairmanText.css"
const Principal = () => {
  return (
    <div className="back">
      <div className="Chairman">
        <div className="background_administration" style={{ height: "fit-content",paddingBottom:"0.5cm" }}>
          <div style={{ width: "40%", margin: "auto" }}>
            <img src={principalImg} alt="chairman" className="administration_Img" />
          </div>
          <div className="administration_details">
            <h1 style={{ fontWeight: "bold" }}>Sri M.V.Koteswara Rao</h1>
            <h3 style={{ fontWeight: "bold" }}>(degree)</h3>
            <h2 style={{ fontWeight: "bold" }}>Founder and Chairman</h2>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Principal;
