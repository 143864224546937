import React, { useState } from 'react'
import './SmceStudentGrivanceCell.css'
import Introduction from './Introduction'
import Objective from './Objective'
import Mechanism from './Mechanism'
import GrievanceCommitee from './GrievanceCommitee'

const SmceStudentGrivanceCell = () => {
    const [show,setShow] = useState("")
    return (
        <div>
        <div className='SmceStudentGrivanceCell'>
            
                <div className='gr_btn_cntr'>
                    <button onClick={() => setShow("")}>
                        Introduction
                    </button>
                    <button onClick={() => setShow("Objective")}>
                        Objectives
                    </button>
                    <button onClick={() => setShow("Mechanism")}>
                        Mechanism to resolve and 
                         disposal of grievances
                    </button>
                    <button onClick={() => setShow("GrievanceCommitee")}>
                        Grievance Redressal Committee
                    </button>
                    <button>
                        Download more Information
                    </button>
                    <button>
                        Online Grievance Submission
                    </button>
                </div>
            
            <div className='gr_body_cntr'>
                {show == "" && (<Introduction/>)}
                {show == "Objective" && (<Objective />)}
                {show == "Mechanism" && ( <Mechanism/>)}
                {show == "GrievanceCommitee" && (<GrievanceCommitee/>)}
            </div>
        </div>
        </div>
    )
}

export default SmceStudentGrivanceCell
