import React from "react";
import { Link } from "react-router-dom";
import principal from "../../assets/cat-4.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import principalImg from "../../assets/mittapalliPrince.jpg.jpg"
import "../CheairmanText.css"
const Principal = () => {
  return (
    <div className="back">
      <div className="Chairman">
        <div className="background_administration">
          <div style={{ width: "40%", margin: "auto" }}>
            <img src={principalImg} alt="chairman" className="administration_Img" />
          </div>
          <div className="administration_details">
            <h1 style={{ fontWeight: "bold" }}>Sri M.V.Koteswara Rao</h1>
            <h3 style={{ fontWeight: "bold" }}>(degree)</h3>
            <h2 style={{ fontWeight: "bold" }}>Founder and Chairman</h2>
          </div>
        </div>
        <div>
          <p className="font_st">
            It is my immense pleasure to welcome you to Sri Mittapalli College of Engineering an institution under Sri Mittapalli Group of Institutions founded a decade ago by Sri. M.V. Koteswararao. I assert that ?Education is the great engine of personal and professional development for students?. SMCE provides a noble engineering education which is a combination of ethical values, innovative technical knowledge, creative thinking and scope for extensive exploration in science and technology.


            <br />
            We lay special emphasis on Outcome Based Education and experimental learning. Our special academic program in addition to the university curriculum navigates students to gain excellent theoretical knowledge thinking skills, analytical frameworks, entrepreneurial skills, and interpersonal and communication skills.
            <br />
            SMCE has established strong relationships with industry and over thirty prominent companies in various technical fields throughout India. There is also an abundant scope in co-curricular and extra-curricular activities to manifest these technocrat?s spirit.
            <br/>
            I admire our dedicated faculty who are well qualified and work towards not just developing knowledge but also instilling the right attitude, and technical skills among engineering students for a holistic relevant in this globalized scenario.
            <br/>
            Moreover, discipline in SMCE is a non-negotiable factor in a student?s life which inculcates the value of punctuality and time management.
I am proud to state that the college, with its rich legacy will sustain to shape the career of future young engineers of our country and transform their potential into global business leaders to serve our nation.
<br/><br/>
<p style={{textAlign:"right"}}>My Best regards to All!
<br/>
Principal</p>

          </p>
        </div>
      </div>
    </div>
  );
};

export default Principal;
