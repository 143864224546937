import React from 'react'
import DPharmaPB from "../../assets/SMPC/DPharm(pb).jpg";

const PharmaDPB = () => {
    const paragraphStyle = {
        fontFamily: "Georgia, serif"
    };

    return (
        <div>
            <h2 style={{ textAlign: "center", fontWeight: "bold", boxShadow: "0 5px 10px -5px green", margin: "auto", padding: "10px", color: "white", backgroundColor: "rgb(47,58,88)" }}>Pharma.D(PB)</h2>
            <br />

            <div style={{ marginTop: "1cm", display: "flex", width: "98%", margin: "auto" }}>
                <img src={DPharmaPB} alt="bpharm" style={{ width: "40%", borderRadius: "10px", height: "9cm", boxShadow: "0 0px 10px 1px green" }} />
                <div style={{ width: "50%", margin: "auto" }}>
                    <h4 style={{ fontWeight: "bold" }}>Pharma.D(PB)</h4>
                    <p style={paragraphStyle}>
                    The Doctor of Pharmacy- Post Baccalaureate (abbreviated as Pharm.D- PB) program in India is an advanced professional pharmacy degree designed for individuals who have already completed a Bachelor of Pharmacy (B.Pharm) degree. It is a postgraduate doctoral program aimed at providing specialized clinical training and expertise in pharmaceutical care.
                    </p>

                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Scope of Pharm.D (Post-Baccalaureate)</h4>
                    <p style={paragraphStyle}>The students who pursue this course undergo rigorous training in various healthcare settings, including hospitals, clinics, and community pharmacies, where they work closely with healthcare teams to optimize medication therapy and improve patient outcomes.</p>
                    <p style={paragraphStyle}>The curriculum typically includes advanced coursework in Pharmacotherapy, Pharmacology, Pharmacoepidemiology, Clinical research methodology, Pharmacokinetics, and Pharmacodynamics. Additionally, students participate in experiential learning rotations to gain practical experience in diverse areas of pharmacy practice, such as ambulatory care, critical care, infectious diseases, and medication therapy management.</p>
                    <p style={paragraphStyle}>Upon successful completion of the Pharm.D (Post-Baccalaureate) program, graduates are equipped with the necessary skills and knowledge to assume advanced practice roles in clinical pharmacy, patient care, medication management, and pharmaceutical research. They play a crucial role in healthcare teams, collaborating with physicians, nurses, and other healthcare professionals to ensure safe and effective medication use.</p>
                    <p style={paragraphStyle}>Overall, the Pharm.D (Post-Baccalaureate) program in India offers pharmacists an opportunity to advance their careers, enhance their clinical expertise, and contribute significantly to improving patient care and healthcare outcomes in diverse healthcare settings.</p>
                    <p style={paragraphStyle}>PharmD graduates will have job opportunities in -</p>
                    <ol>
                        <li style={{ ...paragraphStyle }}>Clinical Pharmacists</li>
                        <li style={{ ...paragraphStyle }}>Medical writing</li>
                        <li style={{ ...paragraphStyle }}>Hospitals and Healthcare Centres</li>
                        <li style={{ ...paragraphStyle }}>Clinical Trials</li>
                        <li style={{ ...paragraphStyle }}>Community Pharmacy</li>
                        <li style={{ ...paragraphStyle }}>Academics</li>
                        <li style={{ ...paragraphStyle }}>Pharmacovigilance and Drug Safety</li>
                        <li style={{ ...paragraphStyle }}>Drug regulatory</li>
                    </ol>
                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Eligibility:</h4>
                    <ul>
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>Candidate shall have passed 10+2 examination conducted by the respective State / Central government authorities with Physics, Chemistry, Mathematics (P.C.M) and or Biology (P.C.B/ P.C.M.B) as optional subjects individually with the required percentage of marks (50% overall Grade point average).

                        </li><br />
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>Candidates who have successfully completed B. Pharm from institutes recognized by PCI can apply for this programme. The candidates must have secured the required percentage of marks (50% aggregate in graduation) in order to pursue this programme.</li><br />
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>Qualifying at the entrance examinations (TS PGECET) conducted by Telangana State council of Higher Education.</li>
                    </ul>

                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Duration of the Course</h4>
                    <p style={paragraphStyle}>The duration of the course shall be three academic years during which 2 years is academic session and third year is completely bound to internship or residency training in hospital, involving posting in speciality units. Duringthird year, students are exposed to actual Pharmacy practice or Clinical pharmacy services and acquires skill under supervision so that he or she may become capable of functioning independent.</p>


                    <br />

                </div>
            </div>
            <br />
        </div>
    );
}

export default PharmaDPB
