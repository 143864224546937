import React from 'react'
import MPharm from "../../assets/SMPC/MPharm.jpg";

const MPharmacy = () => {

    const paragraphStyle = {
        fontFamily: "Georgia, serif"
    };
    return (
        <div>
            <h2 style={{ textAlign: "center", fontWeight: "bold", boxShadow: "0 5px 10px -5px green", margin: "auto", padding: "10px", color: "white", backgroundColor: "rgb(47,58,88)" }}>M.Pharmacy</h2>
            <br />

            <div style={{ marginTop: "1cm", display: "flex", width: "98%", margin: "auto" }}>
                <img src={MPharm} alt="bpharm" style={{ width: "40%", borderRadius: "10px", height: "9cm", boxShadow: "0 0px 10px 1px green" }} />
                <div style={{ width: "50%", margin: "auto" }}>
                    <h4 style={{ fontWeight: "bold" }}>M.Pharmacy</h4>
                    <p style={paragraphStyle}>
                        A Master of Pharmacy (abbreviated as M.Pharm) is a post graduate academic degree course which embarks on a journey of advanced learning and exploration in Pharmaceutical sciences. Designed for graduates in the Pharmaceutical field, this postgraduate academic degree course delves deep into various facets of Pharmacy, equipping students with a comprehensive understanding of Pharmacokinetics, Drug regulatory affairs, Pharmaceutical manufacturing technology, and more.
                    </p>
                    <p style={paragraphStyle}>
                        At our institution, we offer two specialized tracks within the M Pharm program:
                    </p>

                    <ol style={{ ...paragraphStyle }}>
                        <li style={{ ...paragraphStyle }}><b>M.Pharm (Pharmaceutics):</b> In this specialization, students focus on the formulation, development, and delivery of pharmaceutical products. From understanding the principles of dosage form design to mastering techniques for drug delivery optimization, students gain valuable insights and practical skills essential for careers in pharmaceutical research, development, and manufacturing.</li><br/>
                        <li style={{ ...paragraphStyle }}><b>M.Pharm (Pharmacology): </b>Students in this specialization explore the effects of drugs on biological systems, studying mechanisms of action, therapeutic applications, and potential side effects. With a strong emphasis on experimental pharmacology and drug evaluation, students develop critical thinking skills and research expertise necessary for roles in drug discovery, clinical research, and regulatory affairs.</li>

                    </ol>
                    <p style={paragraphStyle}>Join us as we embrace the opportunities and challenges presented by modernization in medical science, and embark on a fulfilling journey towards advancing pharmaceutical knowledge and innovation.</p>
                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Duration of the Course</h4>
                    <p style={paragraphStyle}>The course of study for M.Pharm shall extend over a period of two academic years(4 semesters) during which3 semesters is academic session and 4th semester is completely bound to Project work.</p>
                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Admission Procedure</h4>
                    <p style={paragraphStyle}>In order to get admission in the course, candidates must meet the entry criteria for M.Pharmas mentioned below.</p>

                    <ul>
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>B.Pharm degree examination from an institution approved by Pharmacy council of India and has scored the required percentage of marks (50 % aggregate for 4 years of B.Pharm).

                        </li><br/>
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>Qualifying at the entrance examinations (TS PGECET) conducted by Telangana State Council of Higher Education and other National level Exams (GPAT).</li><br/>
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>Every student selected for admission to post graduate pharmacy program should have obtained registration with the state pharmacy council or should obtain the same within one month from the date of his / her admission.</li>
                    </ul>
                    <br />

                </div>
            </div>
            <br />
        </div>
    );
}

export default MPharmacy
