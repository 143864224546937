import React from 'react';
import BPharm from "../../assets/SMPC/BPharm.jpg";

const paragraphStyle = {
    fontFamily: "Georgia, serif"
};

const BPharmacy = () => {
    return (
        <div>
            <h2 style={{ textAlign: "center", fontWeight: "bold", boxShadow: "0 5px 10px -5px green", margin: "auto", padding: "10px", color: "white", backgroundColor: "rgb(47,58,88)" }}>B.Pharmacy</h2>
            <br />

            <div style={{ marginTop: "1cm", display: "flex", width: "98%", margin: "auto" }}>
                <img src={BPharm} alt="bpharm" style={{ width: "40%", borderRadius: "10px", height: "9cm", boxShadow: "0 0px 10px 1px green" }} />
                <div style={{ width: "50%", margin: "auto" }}>
                    <h4 style={{ fontWeight: "bold" }}>B. Pharmacy</h4>
                    <p style={paragraphStyle}>
                        The Bachelor of Pharmacy (abbreviated as B.Pharm) is an undergraduate degree program which is designed to provide students with a thorough understanding of Pharmaceutical sciences and allied disciplines. Through a combination of theoretical coursework, laboratory sessions, and practical training, students acquire the necessary skills and knowledge to excel in the field of Pharmacy.
                    </p>
                    <p style={paragraphStyle}>
                        Upon completion of the B.Pharm degree, graduates are eligible to pursue licensure as Pharmacists in many countries, enabling them to actively contribute to healthcare delivery and patient care. This degree serves as a vital stepping stone for individuals aspiring to pursue a fulfilling career in pharmacy, offering diverse opportunities in areas such as Community pharmacy, Hospital pharmacy, Industrial pharmacy, Research and development, Regulatory pharmacy, and Academia.
                    </p>
                    <p style={paragraphStyle}>
                        With its emphasis on both theoretical concepts and hands-on experience, the B.Pharm program equips students with the expertise needed to address the evolving challenges and advancements within the pharmaceutical industry. It is a highly regarded and sought-after course for individuals keen on making a meaningful impact in healthcare and improving the quality of life for patients worldwide.
                    </p>
                    <h5 style={{ fontWeight: "bold", color: "blue" }}>Scope of B.Pharmacy course</h5>
                    <p style={paragraphStyle}>A qualified Pharmacist has a brilliant career in several areas one might choose including working for oneself, in the commercial sector, or even for the Government. Candidates can pursue a Masters in Pharmacy up to a Ph.D. level and work as a University Professor/ Scientist/ R & D expert etc.</p>
                    <p style={paragraphStyle}>Following a B.Pharma, one can work or find employment in the following fields:</p>
                    <ol style={paragraphStyle}>
                        <li>Analyst in Pharmaceutical Industries</li>
                        <li>Drug Inspector</li>
                        <li>Government Analyst</li>
                        <li>Clinical Pharmacist</li>
                        <li>Community Pharmacist</li>
                        <li>Hospital Pharmacist</li>
                        <li>Research Scientist</li>
                        <li>Research and Development Executive</li>
                        <li>Medical Writer</li>
                        <li>Clinical Researcher</li>
                        <li>Medical Representative</li>
                    </ol>
                    <h5 style={{ fontWeight: "bold", color: "blue" }}>Duration of the Course</h5>
                    <p style={paragraphStyle}>The course of study for B.Pharm shall extend over a period of 4 years (8 semesters) and 3 years (6 semesters) for lateral entry students.</p>
                    <h5 style={{ fontWeight: "bold", color: "blue" }}>Admission Procedure</h5>
                    <p style={paragraphStyle}>In order to get admission in the course, candidates must meet the entry criteria for B.Pharm as mentioned below:</p>
                    <h6 style={{ fontWeight: "bold" }}>First Year</h6>
                    <ol>
                        <li style={{ ...paragraphStyle }}>Candidate shall have passed 10+2 examination conducted by the respective State/ Central government authorities with Physics, Chemistry, Mathematics (P.C.M) and or Biology (P.C.B/ P.C.M.B) as optional subjects individually with required percentage of marks (50% overall Grade point average).</li>
                        <li style={{ ...paragraphStyle }}>Qualifying at the entrance examination (EAPCET) conducted by the state council of Higher Education.</li>
                    </ol>
                    <br />
                    <h6 style={{ fontWeight: "bold" }}>Second Year (Lateral Admission)</h6>
                    <ol>
                        <li style={{ ...paragraphStyle }}>A pass in Diploma in Pharmacy (D.Pharm) course from an institution approved by the Pharmacy council of India under section 12 of the Pharmacy Act.</li>
                        <li style={{ ...paragraphStyle }}>Qualifying at the Entrance examination (E-CET) conducted by the State Council of Higher Education.</li>
                    </ol>
                </div>
            </div>
            <br />
        </div>
    );
}

export default BPharmacy;
