import React from "react";
import "./Menubar.css";
import "./Header.css";
// import logo from "../../assets/logo2r.png";
import "./Header.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import UGC from "../../assets/UGC.jpg";
import AICTE from "../../assets/AICTE.png";
import ISO from "../../assets/ISO.png";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ecaplogo from "../../assets/ecap-graphic.png";
import NaacImg from "../../assets/SMCE/Naac.jpeg";
import nba from "../../assets/SMCE/NBA.pdf";
import jntu from "../../assets/SMCE/JNTU.pdf";
import iso from "../../assets/SMCE/ISO.jpg"
import aicte from "../../assets/SMCE/AICTE.pdf"
import examsPortal from "../../assets/icons/exmstool.png"
import Nirf from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-ENGG.pdf" 






import pdf from "../../assets/pdfsmittapalli/2019-20-BOG.pdf";
import pdf1 from "../../assets/pdfsmittapalli/2018-19-BOG.pdf";
import pdf2 from "../../assets/pdfsmittapalli/2017-18-governingbody.pdf";
import pdf3 from "../../assets/pdfsmittapalli/2020-21-BOG.pdf";
import pdf4 from "../../assets/pdfsmittapalli/2021-22-BOG.pdf";
// training&examcell
import pdf6 from "../../assets/pdfsmittapalli/2019-20-7training&placement.pdf";
import pdf7 from "../../assets/pdfsmittapalli/2020-21-4training&placement.pdf";
import pdf8 from "../../assets/pdfsmittapalli/2021-22-6training&placement2022.pdf";
import pdf9 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";
import pdf10 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";

import pdf11 from "../../assets/pdfsmittapalli/CGCELL.pdf";
import pdf12 from "../../assets/pdfsmittapalli/edcell.pdf";
import pdf13 from "../../assets/pdfsmittapalli/IIIC.pdf";

// EAO report
import pdf14 from "../../assets/pdfsmittapalli/EOA_Report_2019-20-4.PDF-1.pdf";
import pdf15 from "../../assets/pdfsmittapalli/EOA_Report_2020-21-1.pdf";
import pdf16 from "../../assets/pdfsmittapalli/EOA-Report_2018-19-1-1.pdf";
import pdf17 from "../../assets/pdfsmittapalli/EOA-Report-21-22-1.PDF-1.pdf";
import pdf18 from "../../assets/pdfsmittapalli/EOA-Report-2013-141-1.pdf";
import pdf19 from "../../assets/pdfsmittapalli/EOA-Report-2014-15-1.pdf";
import pdf20 from "../../assets/pdfsmittapalli/EOA-Report-2015-16-1.pdf";
import pdf21 from "../../assets/pdfsmittapalli/EOA-Report-2016-17-1.pdf";
import pdf22 from "../../assets/pdfsmittapalli/EOA-Report-2017-18-1.pdf";
import pdf23 from "../../assets/pdfsmittapalli/2006-AICTE-approval-1-1.pdf";
import pdf24 from "../../assets/pdfsmittapalli/aicte-approval-2008-9-1.pdf";
import pdf25 from "../../assets/pdfsmittapalli/aicte-approval-2009-2010-1.pdf";
import pdf26 from "../../assets/pdfsmittapalli/aicte-approval-2010-1-1.pdf";
import pdf27 from "../../assets/pdfsmittapalli/AICTE-Revised-Approval-Letter-2012-2013-u9-1.pdf";
// nba
import pdf28 from "../../assets/pdfsmittapalli/NBA-LETTER-2022.pdf";


import pdf29 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-1MBA.pdf";
import pdf30 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-ENGG.pdf";
import pdf31 from "../../assets/pdfsmittapalli/LETTER-FROM-NAAC-1.pdf";
import pdf32 from "../../assets/pdfsmittapalli/B.TECH-FEE-GO-23122020HE_MS48feestructre.pdf";
import pdf33 from "../../assets/pdfsmittapalli/7.1.3-extension.pdf";
import pdf34 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";

// aictesanc

import pdf35 from "../../assets/pdfsmittapalli/sanc-2017-18.pdf";
import pdf36 from "../../assets/pdfsmittapalli/sanc-2018-19.pdf";
import pdf37 from "../../assets/pdfsmittapalli/sanc-2019-20.pdf";
import pdf38 from "../../assets/pdfsmittapalli/sanc-2020-21.pdf";

import udpg from "../../assets/pdfsmittapalli/UG-PG-APPLICATION-2022.pdf";

// ug pg regulation
import ugR13 from "../../assets/pdfsmittapalli/ugR13.pdf";
import ugR16 from "../../assets/pdfsmittapalli/ugR16.pdf";
import ugR19 from "../../assets/pdfsmittapalli/ugR19.pdf";
import ugR20 from "../../assets/pdfsmittapalli/ugR20.pdf";
import pgR19 from "../../assets/pdfsmittapalli/pgR19.pdf";
import MTECHCSE from "../../Pdf/course_syllabus/PG/M.Tech CSE Syllabus.pdf";
import MTECHVLSEES from "../../Pdf/course_syllabus/PG/M.Tech VLSI&ES Syllabus.pdf";
import MR23MBA from "../../Pdf/course_syllabus/PG/MR23_MBA I, II SEM SYLLABUS.pdf";



//UG Course Sructure
import UGMr23BTECH from "../../Pdf/course_syllabus/UG/MR23_B.Tech_ SYLLABUS.pdf"
import UGR20AI from "../../Pdf/course_syllabus/UG/R20 AI Syllabus.pdf"
import UGR20CSE from "../../Pdf/course_syllabus/UG/R20 CSE Syllabus.pdf"
import UGR20DS from "../../Pdf/course_syllabus/UG/R20 DS Syllabus.pdf"
import UGR20ECE from "../../Pdf/course_syllabus/UG/R20 ECE Syllabus.pdf"
import UGR20IT from "../../Pdf/course_syllabus/UG/R20 IT Syllabus.pdf"


import fee from "../../assets/pdfsmittapalli/FeeStructure.docx";

import banner from "../../assets/SMPC/SMPCBanner.png"


import { Link, useLocation } from "react-router-dom";
import Advertisement from "../CategoryB/Advertisement";

//Previus QP
import PQPBTech from "../../Pdf/PreviusQP/Autonomous_I B.Tech I Sem Question Papers_Feb-2024.pdf"
import PQPMTech from "../../Pdf/PreviusQP/Autonomous_M.Tech I Sem Question Papers_Feb-2024.pdf"
import PQPMBA from "../../Pdf/PreviusQP/Autonomous_MBA I Sem Question Papers_Feb-2024.pdf"

//Academic Regulation
import ARUGMR23 from "../../Pdf/AcademicRegulation/UG/MR23_B.Tech_Acedemic-Regulations.pdf"
import ARUGR20 from "../../Pdf/AcademicRegulation/UG/R20 B-Tech Course Regulations.pdf"

import ARPGMBA from "../../Pdf/AcademicRegulation/PG/MBA-MR23 (R19)-REGULATIONS.pdf"
import ARPGMTech from "../../Pdf/AcademicRegulation/PG/M.Tech-MR23 (R19)_regulations.pdf"

//Syllabus
import SBUGMR23 from "../../Pdf/Syllabus/UG/MR23_B.Tech_ SYLLABUS.pdf"
import SBUGAI from "../../Pdf/Syllabus/UG/R20 AI Syllabus.pdf"
import SBUGCSE from "../../Pdf/Syllabus/UG/R20 CSE Syllabus.pdf"
import SBUGDS from "../../Pdf/Syllabus/UG/R20 DS Syllabus.pdf"
import SBUGECE from "../../Pdf/Syllabus/UG/R20 ECE Syllabus.pdf"
import SBUGIT from "../../Pdf/Syllabus/UG/R20 IT Syllabus.pdf"

import SBPGMTechCSE from "../../Pdf/Syllabus/PG/M.Tech CSE Syllabus.pdf"
import SBPGMTechVLSI from "../../Pdf/Syllabus/PG/M.Tech VLSI&ES Syllabus.pdf"
import SBPGMBAMR23 from "../../Pdf/Syllabus/PG/MR23_MBA I, II SEM SYLLABUS.pdf"
import RTI from "../../assets/pdfsmittapalli/RTI.pdf";






const handleOpenPdf = (pdf) => {
  window.open(pdf, "_blank")
}

const Menubar = () => {

  //   function changeColor() {
  //     var logo = document.getElementById('notification_icon');
  //     var colors = ['white', 'yellow',"red",];

  //     var randomColor = colors[Math.floor(Math.random() * colors.length)];
  //     logo.style.color = randomColor;
  // }

  // setInterval(changeColor, 100);

  const location = useLocation();
  return (
    <div>
      <div className="col-sm-12 ntf_banner_cnt">
        <div className="d-flex align-items-justify gap-5 ntf_bg_cnt ">

          <h5 className="ntm_prg"><b>APEAPCET/PGECET/ ECET/ICET Code :</b><b style={{ fontWeight: 900 }}>SMPC</b></h5>

          <h5 className="ntm_prg"><b style={{ fontWeight: 700 }}>College Code :(JNTUK)</b><b style={{ fontWeight: 900 }}> U9</b> </h5>

          <p className="ntm_prgecap">
            <Link to="http://103.42.248.219/ecap/default.aspx" target="_blank"><img className="ecap_logo" src={ecaplogo}></img></Link>
          </p>
          <div>
            <a >
              <Link to="http://smceexamcell.in/examcell/" target="_blank"><img className="examsPortal" src={examsPortal}></img></Link>

            </a>
          </div>


          {/* <p className="ntm_prgecap">
            <a href="#">
              <img src={Banner} alt="ecap"/>
            </a>{" "}
          </p>   */}

        </div>
      </div>
      <div style={{ width: "100%" }}>
        <img src={banner} alt="Banner Sai Spurthi" className="sai_banner_img" />
      </div>
      <header>
        <input type="checkbox" id="menu-bar" />
        <label for="menu-bar">Menu</label>
        <nav className="navbar">
          <ul>
            {/* Home */}
            <li>
              <Link
                to="/"
          
              >
                HOME
              </Link>
            </li>
            {/* about us */}
            <li>
              <Link
                to="/about"

              >
                ABOUT
              </Link>
              <ul>
                <li>
                  <Link to="/VisionMision">Vission & Mission</Link>
                </li>
                <li>
                  <Link to="/Chairman">Chairman Message</Link>
                </li>
                <li>
                  <Link to="/Administration/principal">Principal Message</Link>
                </li>
                <li>
                  <Link to="/governingbody">Governing Body</Link>
                </li>
              </ul>
            </li>
            {/* Academic */}
            <li>
              <Link to="#">ADMINISTRATION</Link>
              <ul>
                <li>
                  <Link to="/About/principal">
                    PRINCIPAL
                  </Link>

                </li>
                <li>
                  <Link to="/grevance">
                    GREVANCE COMMITTEE
                  </Link>
                </li>
              </ul>
            </li>
            {/* Departments */}
            <li>
              <Link to="#">COURSES</Link>
              <ul>
                <li>
                  <Link to="/courses/bpharma">B.PHARMACY</Link>
                </li>
                <li>
                  <Link to="/courses/mpharma">M.PHARMACY</Link>
                </li>
                <li>
                  <Link to="/courses/PharmaD">PHARMA.D</Link>
                </li>
                <li>
                  <Link to="/courses/PharmaDPB">PHARMA.D(PB)</Link>
                </li>
              </ul>
            </li>
            {/* Faculty */}

            <li>
              <Link to="#">FACULTY</Link>
              <ul>
                <li>
                  <Link to="#">TEACHING STAFF</Link>
                </li>
                <li>
                  <Link to="#">NON-TEACHING STAFF</Link>
                </li>
              </ul>
            </li>

            <li className="naccli">
              <Link to="#">ACHIEVEMENTS</Link>



              <ul>
                <li>
                  <Link to="#">PLACEMENTS</Link>
                </li>
                <li>
                  <Link to="#">GOLD MEDALISTS</Link>
                </li>
                <li>
                  <a href={aicte} target="_blank">AICTE GRANTS</a>
                </li>

              </ul>


            </li>



            {/* Naac */}
            <li className="naccli">
              <Link to="#">NAAC </Link>



              <ul>
                <li>
                  <Link to="/naac/naacSsr">AQAR 2022-23</Link>
                </li>
                <li>
                  <Link to="/naac/ict">ICT</Link>
                </li>
                <li>
                  <Link to="/naac/extendedprofile">Extended Profile</Link>
                </li>

              </ul>


            </li>



            {/* Gallery */}
            <li>
              <Link to="/gallery">GALLERY </Link>
             
            </li>
            {/* Facilities */}
            <li>
              <Link to="#">FACILITIES</Link>
              <ul>
                <li>
                  <Link to="/amenities/library">Digital Library</Link>
                </li>
                <li>
                  <Link to="/student/SmceStudentGrivanceCell">Student Grievance Cell</Link>
                </li>
                <li>
                  <Link to="/amenities/e-classRooms">Classrooms</Link>
                </li>
                <li>
                  <Link to="/amenities/ictFecilities/lab">
                    Laboratories
                  </Link>
                </li>
                <li>
                  <Link to="/amenities/hostels">Hostel</Link>
                </li>
                <li>
                  <Link to="/amenities/transport">Transport</Link>
                </li>
                <li>
                  <Link to="#">
                    NSS
                  </Link>
                </li>
                <li>
                  <Link to="/HealthCenter">Health Center</Link>
                </li>
                <li>
                  <Link to="#">Auditorium</Link>
                </li>
                <li>
                  <Link to="/amenities/cafeteria">Canteen</Link>
                </li>



              </ul>
            </li>
            {/* contact */}
            <li>
              <Link to="/contactus">CONTACT </Link>
            </li>
            <li>
              <Link to="#">OTHERS</Link>
              <ul>
                <li>
                  <Link to="#">ALUMNI</Link>
                </li>
                <li>
                  <a href={Nirf} target="_blank">NIRF</a>
                </li>
                <li>
                  <Link to="#">MOU'S</Link>
                </li>
                <li>
                  <a href={RTI} target="_blank">RTI</a>
                </li>
                {/* <li>
                  <Link to="#">SIF REPORT</Link>
                </li> */}
                <li>
                  <a href={aicte} target="_blank">MANDATORY DISCLOSURE</a>
                </li>
              </ul>
            </li>


          </ul>
        </nav>
      </header>
      <div className="col-sm-12 notification_banner_cnt">
        <marquee behavior="scroll" direction="left" scrollamount="10">
          <div className="d-flex align-items-center gap-3 notification_bg_cnt">
            <NewReleasesIcon
              className="heartbeat"
              id="notification_icon"
              sx={{ fontSize: 30, color: "yellow" }}
            />
            <p className="notification_prg">
              Admissions for the academic year 2024-25 are in progress, for details contact 9000447117
            </p>
          </div>
        </marquee>
      </div>
    </div>
  );
};

export default Menubar;
