import React from 'react'
import DPharma from "../../assets/SMPC/DPharm.jpg"

const PharmaD = () => {
    const paragraphStyle = {
        fontFamily: "Georgia, serif"
    };

    return (
        <div>
            <h2 style={{ textAlign: "center", fontWeight: "bold", boxShadow: "0 5px 10px -5px green", margin: "auto", padding: "10px", color: "white", backgroundColor: "rgb(47,58,88)" }}>Pharma.D</h2>
            <br />

            <div style={{ marginTop: "1cm", display: "flex", width: "98%", margin: "auto" }}>
                <img src={DPharma} alt="bpharm" style={{ width: "40%", borderRadius: "10px", height: "9cm", boxShadow: "0 0px 10px 1px green" }} />
                <div style={{ width: "50%", margin: "auto" }}>
                    <h4 style={{ fontWeight: "bold" }}>Pharma.D</h4>
                    <p style={paragraphStyle}>
                        Doctor of Pharmacy (abbreviated as Pharm.D) course was developed with an objective of developing students to play an important role as Clinical Pharmacist. It was approved by Govt. of India in the year 2008 with Pharmacy Council of India being the apex body governing it.
                    </p>
                 
                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Scope of Pharm.D</h4>
                    <p style={paragraphStyle}>At the end of the course, the Clinical pharmacists develop and acquire knowledge and skill to provide pharmaceutical care services, drug and poison information services, patient counseling, identify drug-drug interactions, monitor adverse drug reactions, carry out therapeutic drug monitoring, help in drug dosage adjustment in special population, assist/co-ordinate in the conduct of clinical trials, promote rational use of medicines and ultimately contribute to better patient care.</p>
                    <p style={paragraphStyle}>There is a huge need of a Pharm D course as now-a-days there are huge drug related problems, irrational drug use and non-compliance to therapy among patients suffering from various chronic diseases. Pharmaceutical care by a physician along with the technical expertise of clinical Pharmacist is the ultimate and best way of achieving better patient care.</p>
                    <p style={paragraphStyle}>PharmD graduates will have job opportunities in -</p>
                    <ol>
                        <li style={{ ...paragraphStyle }}>Clinical Pharmacists</li>
                        <li style={{ ...paragraphStyle }}>Medical writing</li>
                        <li style={{ ...paragraphStyle }}>Hospitals and Healthcare Centres</li>
                        <li style={{ ...paragraphStyle }}>Clinical Trials</li>
                        <li style={{ ...paragraphStyle }}>Community Pharmacy</li>
                        <li style={{ ...paragraphStyle }}>Academics</li>
                        <li style={{ ...paragraphStyle }}>Pharmacovigilance and Drug Safety</li>
                        <li style={{ ...paragraphStyle }}>Drug regulatory</li>
                    </ol>
                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Eligibility:</h4>
                    <ul>
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>Candidate shall have passed 10+2 examination conducted by the respective State / Central government authorities with Physics, Chemistry, Mathematics (P.C.M) and or Biology (P.C.B/ P.C.M.B) as optional subjects individually with the required percentage of marks (50% overall Grade point average).

                        </li><br />
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>Qualifying at the entrance examination (EAPCET) conducted by the State council of Higher Education.</li><br />
                        <li style={{ ...paragraphStyle, marginLeft: "1cm" }}>A pass in Diploma in Pharmacy (D.Pharm) course from an institution approved by the Pharmacy council of India under section 12 of the Pharmacy Act.</li>
                    </ul>

                    <h4 style={{ fontWeight: "bold", color: "blue" }}>Duration of the Course</h4>
                    <p style={paragraphStyle}>The duration of the course shall be six academic years during which 5 years is academic session and sixth year is completely bound to internship or residency training in hospital, involving posting in specialty units. During six years, students are exposed to actual Pharmacy practice or Clinical pharmacy services and acquires skill under supervision so that he or she may become capable of functioning independent.</p>


                    <br />

                </div>
            </div>
            <br />
        </div>
    );
}

export default PharmaD
