import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import carousel1 from "../../assets/images/SS-collageImage.jpg";
import "./Home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import AOS from "aos";
import carousal_img3 from "../../assets/carousal_images/Affiliations.jpg";
import carousal_img4 from "../../assets/carousal_images/c2.jpeg";
import carousal_img5 from "../../assets/carousal_images/Autonomous.jpg";
import carousal_img6 from "../../assets/carousal_images/College Photo.jpg";
import carousal_img7 from "../../assets/carousal_images/c8.jpg";
import carousal_img8 from "../../assets/carousal_images/Felicitation - JNTUK.jpg";
import carousal_img9 from "../../assets/carousal_images/Felictation-APSCHE.jpg";
import Slide from '@mui/material/Slide';
import { useState } from "react";
import { useRef } from "react";
import BPharm from "../../assets/SMPC/BPharm.jpg"
import MPharm from "../../assets/SMPC/MPharm.jpg"
import DPharm from "../../assets/SMPC/DPharm.jpg"
import DPharmPB from "../../assets/SMPC/DPharm(pb).jpg"
import NoPhoto from "../../assets/SMPC/NoImages.jpg"
import GalleryBack from "../../assets/SMPC/GalleryBack.jpg"
import Chairman from "../../assets/chairmanmi.jpg"
import Secretary from "../../assets/Secretarymi.jpg"
import VisePresident from "../../assets/VicePresident.jpg"
import Director from "../../assets/directormi-1.jpg"
import Principal from "../../assets/mittapalliPrince.jpg.jpg"
import { Link } from "react-router-dom";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Home = () => {

  const [open, setOpen] = useState(false);
  const marqueeRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);


  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // Navigate to the next image
  const goNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carousal_images.length);
  };

  // Navigate to the previous image
  const goPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carousal_images.length - 1 : prevIndex - 1
    );
  };


  const handleMouseOver = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseOut = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleClickOpen = () => {
    setOpen(false);
  }
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 300, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 200, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 450, // offset (in px) from the original trigger point
      // delay: 100, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });

    setOpen(true);
  }, []);
  let carousal_images = [
    {
      image: carousal_img3,
    },
    {
      image: carousal_img9,
    },


    {
      image: carousal_img4,
    },
    {
      image: carousal_img5,
    },
    {
      image: carousal_img6,
    },
    {
      image: carousal_img7,
    },
    {
      image: carousal_img8,
    },

    // {
    //   image: carousal_img10,
    // },
  ];

  const course = [
    {
      name: "B.PHARM",
      contant: "The Bachelor of Pharmacy (abbreviated as B.Pharm) is an undergraduate degree program which is designed to provide students with a thorough understanding of Pharmaceutical sciences and allied disciplines.",
      image: BPharm,
      link: "/courses/BPharma"
    },
    {
      name: "M.PHARM",
      contant: "A Master of Pharmacy (abbreviated as M.Pharm) is a post graduate academic degree course which embarks on a journey of advanced learning and exploration in Pharmaceutical sciences.",
      image: MPharm,
      link: "/courses/MPharma"
    },
    {
      name: "D.PHARM",
      contant: "Doctor of Pharmacy (abbreviated as Pharm.D) course was developed with an objective of developing students to play an important role as Clinical Pharmacist. It was approved by Govt. of India in the year 2008 with Pharmacy Council of India being the apex body governing it.",
      image: DPharm,
      link: "/courses/PharmaD"
    },
    {
      name: "D.PHARM(PB)",
      contant: "The Doctor of Pharmacy- Post Baccalaureate (abbreviated as Pharm.D- PB) program in India is an advanced professional pharmacy degree designed for individuals who have already completed a Bachelor of Pharmacy (B.Pharm) degree.",
      image: DPharmPB,
      link: "/courses/PharmaDPB"
    },
  ]
  const leader = [
    {
      img: Chairman,
      name: "Sri M.V.Koteswara Rao",
      designation: "Chairman",
    },
    {
      img: Secretary,
      name: "Sri M.B.V. Satyanarayana",
      designation: "Secretary",
    },
    {
      img: Director,
      name: "Mr.M.S.Chakravarthi",
      designation: "Director",
    },
    {
      img: VisePresident,
      name: "Mr. M.Kishore Kumar",
      designation: "Vise President",
    },
    {
      img: Principal,
      name: "Dr. S. Gopi Krishna",
      designation: "Principal",
    },
  ]

  const prevButton = {
    position: 'absolute',
    left: '20px',
    color: 'white',
    fontSize: '24px',
    backgroundColor: 'green',
    border: 'none',
    borderRadius: '5px',
    padding: '5px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    zIndex: 1001,
    transition: 'background-color 0.3s ease',
  };

  const nextButton = {
    position: 'absolute',
    right: '20px',
    color: 'white',
    fontSize: '24px',
    backgroundColor: 'green',
    border: 'none',
    borderRadius: '5px',
    padding: '5px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    zIndex: 1001,
    transition: 'background-color 0.3s ease',
  };

  const closeButton = {
    position: 'absolute',
    top: '10px',
    right: '20px',
    color: 'white',
    fontSize: '24px',
    backgroundColor: 'red',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    zIndex: 1001,
    transition: 'background-color 0.3s ease',
  };


  return (
    <div className="App">
      <div>
        <div className="carousal_bg_cnt">
          <Slider {...settings}>
            {carousal_images.map((item, index) => {
              return (
                <div key={index}>
                  <div className="card-body">
                    <img
                      className=" carousal_img"
                      src={item.image}
                      alt="carousel1-img"
                    />
                  </div>
                </div>
              );
            })}

          </Slider>
        </div>
      </div>
      <br />
      <br />
      <div>
        <h1 style={{ paddingLeft: "3%", color: "green", marginBottom: "2%", marginTop: "2%" }}>Courses</h1>
        <div style={{ display: "flex" }}>
          {course.map((item, index) => <Link to={item.link} style={{ width: "19%", cursor: "pointer", padding: "2%", margin: "auto", height: "14cm", color: "black", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
            <div>
              <img style={{ width: "100%", height: "4cm", margin: "auto", boxShadow: "0 0px 10px 1px green" }} src={item.image} />
            </div>
            <h4 style={{ textAlign: "center", marginTop: "10px" }}>{item.name}</h4>
            <hr style={{ width: "100%", border: "1px solid black", backgroundColor: "black" }} />
            <div>
              <p style={{ textAlign: "justify" }}>
                {item.contant}
              </p>
            </div>
          </Link>)}
        </div>
      </div>
      <br />
      <br />
      <div style={{ display: "flex", marginTop: "1cm", height: "10cm" }}>
        <img style={{ width: "50%", }} src={carousal_img5} />
        <div style={{ width: "50%", backgroundColor: "rgb(14,86,149)", padding: "2%", color: "white" }}>
          <h1 style={{ fontWeight: "bold" }}>ABOUT US</h1>
          <p style={{ fontSize: "17px" }}>
            <p>Sri Mittapalli College of Pharmacy was established in 2006 with an aim to accomplish distinction in Engineering and Technological pursuits.</p>

            <p>Sri Mittapalli College of Pharmacy approved by AICTE and affiliated to JNTUK, Kakinada is an Autonomous Institution since 2023 and has been accredited by NAAC with 'A+' Grade, NBA and ISO 9001:2015 Certified. It has also been awarded 2(f) status.</p>

           
            Enhanced hands-on Training, well-established infrastructure, updated Software, well-resourced Library,Online Digital Library, Memorandum of Understanding with well-established concerns, Industrial visits, Guest Lectures, <Link to="/about" style={{color:"white",backgroundColor:"green",padding:"5px",borderRadius:"5px"}}> More</Link>

          </p>
          
        </div>
      </div>
      <br />
      <br />
      <div>
        <p style={{ paddingLeft: "3%" }}>
          <h5 style={{ color: "gray" }}>OUR LEADERSHIP</h5>
          <h2 style={{ fontWeight: "bold", color: "blue" }}>PILLER OF STRENGTH</h2>
        </p>
        <br />
        <div style={{ display: "flex" }}>
          {leader.map((item, i) => <div style={{ width: "20%", margin: "auto", textAlign: "center" }}>
            <img style={{ height: "6cm", width: "6cm", margin: "auto", border: "none", padding: "0px", borderRadius: "50%", boxShadow: "0 0px 10px 1px green" }} src={item.img} />
            <br /><br />
            <h3 >{item.name}</h3>
            <p>{item.designation}</p>
          </div>)}
        </div>
      </div>
      <br />
      <br />
      <div style={{ backgroundImage: `url(${GalleryBack})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", padding: "1% 2% 2% 2%" }}>
        <p style={{ marginBottom: "2%", padding: "10px", width: "98%", textAlign: "center", backgroundColor: "white", marginLeft: "1%", boxShadow: "0 0px 10px 1px black" }}>
          <h5 style={{ color: "blue" }}>OUR</h5>
          <h2 style={{ fontWeight: "bold", color: "black" }}>GALLERY</h2>
        </p>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          {carousal_images.map((item, index) => {
            if (index !== 1) {
              return (
                <img
                  key={index}
                  style={{
                    width: "30%",
                    margin: "auto",
                    height: "6cm",
                    border: "5px solid white",
                    boxShadow: "0 0px 10px 1px green",
                    cursor: "pointer" // Add a cursor pointer for better UX
                  }}
                  src={item.image}
                  onClick={() => openModal(index)} // Open modal on click
                />
              );
            }
          })}
        </div>

        {isOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000
            }}
          >
            <button onClick={closeModal} style={closeButton}>
              &times;
            </button>
            <button onClick={goPrev} style={prevButton}>
              &lt; Previous
            </button>
            <img
              src={carousal_images[currentIndex].image}
              alt="carousel"
              style={{ maxHeight: "80%", maxWidth: "80%" }}
            />
            <button onClick={goNext} style={nextButton}>
              Next &gt;
            </button>
          </div>
        )}
      </div>
      <br />
      <br />
    </div>
  );
};

export default Home;
