import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/smcelogo.png";
import pdf28 from "../../assets/pdfsmittapalli/NBA-LETTER-2022.pdf";
import pdf31 from "../../assets/pdfsmittapalli/LETTER-FROM-NAAC-1.pdf";
import pdf5 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";
import pdf29 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-1MBA.pdf";
import pdf30 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-ENGG.pdf";
// import pdf14 from "../../assets/pdfsmittapalli/EOA_Report_2019-20-4.PDF-1.pdf";
// import pdf15 from "../../assets/pdfsmittapalli/EOA_Report_2020-21-1.pdf";
// import pdf16 from "../../assets/pdfsmittapalli/EOA-Report_2018-19-1-1.pdf";
// import pdf17 from "../../assets/pdfsmittapalli/EOA-Report-21-22-1.PDF-1.pdf";
// import pdf18 from "../../assets/pdfsmittapalli/EOA-Report-2013-141-1.pdf";
// import pdf19 from "../../assets/pdfsmittapalli/EOA-Report-2014-15-1.pdf";
// import pdf20 from "../../assets/pdfsmittapalli/EOA-Report-2015-16-1.pdf";
// import pdf21 from "../../assets/pdfsmittapalli/EOA-Report-2016-17-1.pdf";
// import pdf22 from "../../assets/pdfsmittapalli/EOA-Report-2017-18-1.pdf";
// import pdf23 from "../../assets/pdfsmittapalli/2006-AICTE-approval-1-1.pdf";
// import pdf24 from "../../assets/pdfsmittapalli/aicte-approval-2008-9-1.pdf";
// import pdf25 from "../../assets/pdfsmittapalli/aicte-approval-2009-2010-1.pdf";
// import pdf26 from "../../assets/pdfsmittapalli/aicte-approval-2010-1-1.pdf";
// import pdf27 from "../../assets/pdfsmittapalli/AICTE-Revised-Approval-Letter-2012-2013-u9-1.pdf";
// import pdf35 from "../../assets/pdfsmittapalli/sanc-2017-18.pdf";
// import pdf36 from "../../assets/pdfsmittapalli/sanc-2018-19.pdf";
// import pdf37 from "../../assets/pdfsmittapalli/sanc-2019-20.pdf";
// import pdf38 from "../../assets/pdfsmittapalli/sanc-2020-21.pdf";

import pdf from "../../assets/pdfsmittapalli/2019-20-BOG.pdf";
import pdf1 from "../../assets/pdfsmittapalli/2018-19-BOG.pdf";
import pdf2 from "../../assets/pdfsmittapalli/2017-18-governingbody.pdf";
import pdf3 from "../../assets/pdfsmittapalli/2020-21-BOG.pdf";
import pdf4 from "../../assets/pdfsmittapalli/2021-22-BOG.pdf";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-1 mt-0 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container">
        <div className="row ">

          <hr />





        </div>
      </div>

      <div style={{ display: "flex",}}>
        <div style={{margin:"auto"}}>
          <div className="">
            <h5>Social Media Links</h5>
            <div className="d-flex pt-4 ">
              <Link
                className="btn btn-outline-light btn-social"
                to="https://twitter.com/smcengg/status/1091256873553141760?lang=en"
                target="blank"
              >
                <i className="fab fa-twitter"></i>
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://www.facebook.com/smcengg/"
                target="blank"
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link className="btn btn-outline-light btn-social" to="#">
                <i className="fab fa-youtube"></i>
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://www.linkedin.com/in/sri-mittapalli-college-of-engineering-bb311723b?trk=blended-typeahead"
                target="blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://www.instagram.com/mittapalli_rockerz/"
                target="blank"
              >
                <i className="fab fa-instagram"></i>
              </Link>
              <Link className="btn btn-outline-light btn-social" to="#">
                <i className="fab fa-pinterest"></i>
              </Link>

            </div><br />
            <p className="mb-3">
              <i className="fa fa-envelope me-3"></i>
              smce.principal@gmail.com
              <br />
              <i className="fa fa-envelope me-3"></i>smcengg@yahoo.co.in
            </p>
          </div>
        </div>
        <div style={{margin:"auto"}}>
          <div className="" >
            <h4 className="text-white mb-2">Contact</h4>
            <p className="mb-1">
              <i className="fa fa-map-marker-alt me-1"></i>
              <b>Sri Mittapalli College Of Pharmacy</b>
              <br />
              Tummalapalem, NH16,Guntur, <br />
              Andhra Pradesh 522233

              <p className="mb-1">
                Mobile - +91 9000447117, <br />
                Mobile - +91 9032727017
              </p>
            </p>
          </div>
        </div>
        <div style={{margin:"auto"}}>
          <iframe
            className="position-relative rounded "
            src="https://maps.google.com/maps?q=Sri%20Mittapalli%20College%20Of%20Engineering%2C%20Tummalapalem%2C%20NH16%2C%20Guntur%2C%20Andhra%20Pradesh%20522233&t=m&z=10&output=embed&iwloc=near"
            frameborder="0"
            style={{ minHeight: "250px", width: "400px" }}
            allowfullscreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              &copy;{" "}
              <Link className="border-bottom" to="#">
                http://www.smce.ac.in
              </Link>
              , All Right Reserved. Designed By{" "}
              <Link className="border-bottom" to="https://htmlcodex.com">
                Adiverse Technologies
              </Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
