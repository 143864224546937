import React from "react";
import Chairman1 from "../assets/chairmanmi.jpg";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import "./CheairmanText.css";

const Chairman = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="back">
    <div className="Chairman">
      <div className="background_administration">
        <div style={{ width: "40%", margin: "auto" }}>
          <img src={Chairman1} alt="chairman" className="administration_Img" />
        </div>
        <div className="administration_details">
          <h1 style={{ fontWeight: "bold" }}>Sri M.V.Koteswara Rao</h1>
          <h3 style={{ fontWeight: "bold" }}>(degree)</h3>
          <h2 style={{ fontWeight: "bold" }}>Founder and Chairman</h2>
        </div>
      </div>
      <div>
        <p className="font_st">

          I express my gratitude for your interest in Sri Mittapalli
          College of Engineering. I believe “Everything is possible if
          our determination is strong and selfless”. My dream of
          establishing an engineering college to avail the finest
          science and technical education for rural students had come
          true in 2006 as Sri Mittapalli Group of Institutions at
          Tummalapalem in Guntur District and it is affiliated with
          Jawaharlal Nehru Technological University, Kakinada.


          <br/>
            It is my deepest inclination to serve the rural society
            moulding these technocrats into global business leaders of
            tomorrow through dedicated staff t and world-class
            infrastructure. I was immensely pleased to signify that we
            have imparted an academic program in addition to the
            engineering curriculum for social, cultural, scientific,
            economic and technical development.
         <br/>
            SMCE aims at establishing standard academic excellence and
            provides integrated training programs for engineering students
            to meet the challenges in the rapid globalized economic
            conditions. Thus, our Sri Mittapalli Group of Institutions
            have earned goodwill from our students and society at large.
         
        </p>
      </div>
    </div>
    </div>
  );
};

export default Chairman;
