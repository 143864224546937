import React from 'react'
import carousal_img3 from "../../assets/carousal_images/Affiliations.jpg";
import carousal_img4 from "../../assets/carousal_images/c2.jpeg";
import carousal_img5 from "../../assets/carousal_images/Autonomous.jpg";
import carousal_img6 from "../../assets/carousal_images/College Photo.jpg";
import carousal_img7 from "../../assets/carousal_images/c8.jpg";
import carousal_img8 from "../../assets/carousal_images/Felicitation - JNTUK.jpg";
import carousal_img9 from "../../assets/carousal_images/Felictation-APSCHE.jpg";
import { useState } from "react";
import GalleryBack from "../../assets/SMPC/GalleryBack.jpg"

const Gallary = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const openModal = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsOpen(false);
    };

    // Navigate to the next image
    const goNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carousal_images.length);
    };

    // Navigate to the previous image
    const goPrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? carousal_images.length - 1 : prevIndex - 1
        );
    };
    const prevButton = {
        position: 'absolute',
        left: '20px',
        color: 'white',
        fontSize: '24px',
        backgroundColor: 'green',
        border: 'none',
        borderRadius: '5px',
        padding: '5px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        zIndex: 1001,
        transition: 'background-color 0.3s ease',
    };

    const nextButton = {
        position: 'absolute',
        right: '20px',
        color: 'white',
        fontSize: '24px',
        backgroundColor: 'green',
        border: 'none',
        borderRadius: '5px',
        padding: '5px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        zIndex: 1001,
        transition: 'background-color 0.3s ease',
    };

    const closeButton = {
        position: 'absolute',
        top: '10px',
        right: '20px',
        color: 'white',
        fontSize: '24px',
        backgroundColor: 'red',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        zIndex: 1001,
        transition: 'background-color 0.3s ease',
    };
    let carousal_images = [
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },
        {
            image: carousal_img9,
        },

        {
            image: carousal_img3,
        },
        {
            image: carousal_img4,
        },
        {
            image: carousal_img5,
        },
        {
            image: carousal_img6,
        },
        {
            image: carousal_img7,
        },
        {
            image: carousal_img8,
        },

        // {
        //   image: carousal_img10,
        // },
    ];
    return (
        <div>
            <div style={{ backgroundImage: `url(${GalleryBack})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", padding: "1% 2% 2% 2%" }}>
                <p style={{ marginBottom: "2%", padding: "10px", width: "98%", textAlign: "center", backgroundColor: "white", marginLeft: "1%", boxShadow: "0 0px 10px 1px black" }}>
                    <h5 style={{ color: "blue" }}>OUR</h5>
                    <h2 style={{ fontWeight: "bold", color: "black" }}>GALLERY</h2>
                </p>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                    {carousal_images.map((item, index) => {
                        if (index !== 1) {
                            return (
                                <img
                                    key={index}
                                    style={{
                                        width: "30%",
                                        margin: "auto",
                                        height: "6cm",
                                        border: "5px solid white",
                                        boxShadow: "0 0px 10px 1px green",
                                        cursor: "pointer" // Add a cursor pointer for better UX
                                    }}
                                    src={item.image}
                                    onClick={() => openModal(index)} // Open modal on click
                                />
                            );
                        }
                    })}
                </div>

                {isOpen && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0,0,0,0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000
                        }}
                    >
                        <button onClick={closeModal} style={closeButton}>
                            &times;
                        </button>
                        <button onClick={goPrev} style={prevButton}>
                            &lt; Previous
                        </button>
                        <img
                            src={carousal_images[currentIndex].image}
                            alt="carousel"
                            style={{ maxHeight: "80%", maxWidth: "80%" }}
                        />
                        <button onClick={goNext} style={nextButton}>
                            Next &gt;
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Gallary
