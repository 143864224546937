import React from 'react'

const GrievanceCommiteeAdministrators = () => {


  const GrievanceCommiteeAdministrators = [
    {
      name: "Dr.S.Gopi Krishna",
      department: "Principal",
      designation: "Chair person"
    },
    {
      name: "Ch.Srinivasa Rao",
      department: "AO",
      designation: "Convener"
    },
    {
      name: "Dr.V.Madhuri",
      department: "HOD, ASH",
      designation: "Coordinator"
    },
    {
      name: "M.Sujan Kumar",
      department: "Asst.Prof, ASH",
      designation: "Member"
    },
    {
      name: "J.Ramesh",
      department: "Asst.Prof, IT",
      designation: "Member"
    },
    {
      name: "M.Venkata Ramana",
      department: "Asst.Prof, CSE",
      designation: "Member"
    },
    {
      name: "M.Parameswara Rao",
      department: "Asst.Prof, ECE",
      designation: "Member"
    },
    {
      name: "Y.Yesu Babu",
      department: "Asst.Prof, AI",
      designation: "Member"
    },
    {
      name: "Y.Mohana Rao	Asst.Prof, MBA	Member",
      department: "Asst.Prof, MBA",
      designation: "Member"
    },
    {
      name: "L.Narasiah (21U91A0582)",
      department: "IV CSE",
      designation: "Student Member"
    },
    {
      name: "R.Bindu Latha (21U91A05C5)",
      department: "IV CSE",
      designation: "Student Member"
    },
    {
      name: "Chilukuri Keerthi",
      department: "IV CSE",
      designation: "Student Member"
    },
    {
      name: "A.Yasaswini (22U91A0503)",
      department: "III CSE",
      designation: "Student Member"
    },
    {
      name: "M.H.Sai Kiran (22U91A0579)",
      department: "III CSE",
      designation: "Student Member"
    },
    {
      name: "O.SudhakarReddy  (23U91A05A2)",
      department: "II CSE",
      designation: "Student Member"
    },
    {
      name: "T.Kavyanjali  (21U91A0479)	IV ECE	Student Member",
      department: "IV ECE",
      designation: "Student Member"
    },
    {
      name: "A.Sri Ram (22U91A0401)   	III ECE	Student Member",
      department: "III ECE",
      designation: "Student Member"
    },
    {
      name: "P.Abhilash (23U91A0423)",
      department: "II ECE",
      designation: "Student Member"
    },
    {
      name: "SK.Rohith (21U91A4355)",
      department: "IV AI",
      designation: "Student Member"
    },
    {
      name: "M.S.L.Sowjanya (22U91A4325)",
      department: "III AI",
      designation: "Student Member"
    },
    {
      name: "V.Srinivas (23U91A43A5)",
      department: "II AI",
      designation: "Student Member"
    },
    {
      name: "B.Nagalokesh (21U91A1205)",
      department: "IV IT",
      designation: "Student Member"
    },
    {
      name: "K.Nagalakshmi (22U91A1224)",
      department: "III IT",
      designation: "Student Member"
    },
    {
      name: "K,Hemanth (23U91A1214)",
      department: "II IT",
      designation: "Student Member"
    },
    {
      name: "H.Thiruma Reddy (22U91A4413)",
      department: "III DS",
      designation: "Student Member"
    },
    {
      name: "P.Harshini (23U91A4449)",
      department: "II DS",
      designation: "Student Member"
    }

  ]
  return (
    <div style={{ width: "80%",margin:"auto" }}>
      <p className='gr_header'>
        Student Grievance Redressal Committee
      </p>
      <br />
      <p>
        As per the guidelines of AICTE, composition of the institute’s Student Grievance Redressal Committee is as follows.
      </p>
      <table className='gb_table' style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>
              S.NO
            </th>
            <th>
              Name of the Chairman/Member
            </th>
            <th>
              Department
            </th>
            <th>
              Designation
            </th>
          </tr>
        </thead>
        <tbody>
          {GrievanceCommiteeAdministrators.map((each, i) => <tr>
            <td>
              {i + 1}
            </td>
            <td>
              {each.name}
            </td>
            <td>
              {each.department}
            </td>
            <td>
              {each.designation}
            </td>
          </tr>)}
        </tbody>
      </table>
      <br/>
    </div>
  )
}

export default GrievanceCommiteeAdministrators
